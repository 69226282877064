@mixin title-header() {
  color: $key-color;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding: 1.9rem 0;
  z-index: 0;
  line-height: 1;
  overflow: hidden;
}

@mixin title-behind() {
  font-size: 7.5rem;
  width: 100%;
  position: absolute;
  letter-spacing: 3px;
  color: rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}