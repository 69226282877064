.top-navbar {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  .top-menu {
    background-color: $white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .list-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      color: $white;
      height: 100%;
      padding-top: 50px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      -ms-overflow-y: auto;
      overflow-y: auto;

      li {
        display: -webkit-inline-flex;
        display: inline-flex;
        padding: .7rem .4rem;
      }

      a {
        color: $key-color;
        text-decoration: none;
      }

      .top-ft-bottom {
        position: absolute;
        background-color: transparent;
        display: none;

        .btn-dexpo {
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 0;
          color: $white;
          border-radius: 0;
        }
      }

      .btn-logout {
        border-radius: 100px;
        background-color: $white;
        font-weight: normal;
        margin-top: .2rem;
        min-width: 200px;
        font-size: 1em;
        display: none;
      }
    }
  }

  .navbar {
    height: 5.2rem;
    position: relative;
    z-index: 1000;
    background-color: $key-color;
    padding: 1rem 2%;
  }

  .navbar-toggler {
    border: 0;

    &:focus-visible {
      outline: none;
    }

    .navbar-toggler-icon {
      background: transparent;
      position: relative;
      display: inline-block;
      cursor: pointer;
      width: 1.8em;
      height: 1.2em;

      > span {
        position: absolute;
        height: 3px;
        background: $white;
        width: 100%;
        top: 4%;
        left: 0;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        transition: .5s;

        &:nth-child(2) {
          top: 42%;
        }

        &:nth-child(3) {
          top: 80%;
          bottom: 0;
        }
      }
    }

    &[aria-expanded=true] {
      .navbar-toggler-icon {
        > span {
          -webkit-transition: .5s;
          -moz-transition: .5s;
          -ms-transition: .5s;
          -o-transition: .5s;
          transition: .5s;

          &:nth-child(1) {
            -webkit-transform: translateY(8px) rotate(45deg);
            -moz-transform: translateY(8px) rotate(45deg);
            -ms-transform: translateY(8px) rotate(45deg);
            -o-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
          }

          &:nth-child(3) {
            -webkit-transform: translateY(-11px) rotate(-45deg);
            -moz-transform: translateY(-11px) rotate(-45deg);
            -ms-transform: translateY(-11px) rotate(-45deg);
            -o-transform: translateY(-11px) rotate(-45deg);
            transform: translateY(-11px) rotate(-45deg);
          }

          &:nth-child(2) {
            opacity: 0;
            visibility: hidden;
            backface-visibility: hidden;
          }
        }
      }
    }
  }

  .navbar-brand {
    .sm-mobile {
      display: none;
    }
  }

  .top-notify {
    font-size: .8em;
    padding-left: .5em;
  }

  .group-button-top {
    .membership-registration-text {
      display: contents;
      color: white;
    }
    
    .btn-mypage {
      height: 49px;
      width: 179px;
      margin-right: 0.4rem;
      text-align: center;
      line-height: 2.4rem;
      border-radius: .5rem;
    }

    .btn-logout {
      height: 49px;
      width: 179px;
      margin-right: 1rem;
      text-align: center;
      line-height: 2.4rem;
      border-radius: .5rem;
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .top-navbar {
    .navbar {
      padding: 1rem 1.5%;
    }

    .top-notify {
      font-size: .7em;
    }

    .group-button-top {
      .btn-logout, .btn-mypage {
        width: 155px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .top-navbar {
    .navbar-brand {
      position: relative;
    }

    .top-notify {
      position: absolute;
      top: 100%;
      left: -5px;
      font-size: .6em;
    }

    .group-button-top {
      .btn-logout, .btn-mypage {
        margin-right: 0;
        width: 155px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .top-navbar {
    .group-button-top {
      .membership-registration-text {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top-navbar {
    .top-menu {
      .list-menu {
        padding-top: 6rem;

        .top-ft-bottom {
          display: flex;
        }

        .btn-logout {
          display: inline-block;
        }
      }
    }

    .navbar {
      padding-top: .5rem;
      padding-bottom: .5rem;

      .navbar-brand {
        width: 52%;
        margin-right: 0rem;

        img {
          display: none;

          &.sm-mobile {
            width: auto;
            max-width: 100%;
            max-height: 40px;
            display: inline-block;
          }
        }
      }
    }

    .top-notify {
      display: none;
    }

    .group-button-top {
      .membership-registration-text {
        display: none;
      }
      
      .btn-mypage {
        font-size: .85em;
        min-width: 75px;
        width: 145px;
        margin-right: .4rem;

        &.btn-sw {
          display: none;
        }
      }

      .btn-logout {
        display: none;

        &.btn-sw {
          display: inline-block;
          font-size: .85em;
          min-width: 75px;
          width: 145px;
          margin-right: .4rem;
        }
      }

      .navbar-toggler {
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .top-navbar {
    .navbar {
      height: 4rem;
      padding: 12px;
    }

    .navbar-brand {
      margin-right: 0;

      img {
        max-width: 260px;
      }
    }

    .group-button-top {
      .btn-logout, .btn-mypage {
        width: 95px !important;
        height: 40px;
        line-height: 30px !important;
      }

      .navbar-toggler {
        padding-left: 0;
      }
    }
  }
}
