.form-control {
  background: $white 0% 0% no-repeat padding-box;
  border: $border-form;
  outline: none;
  font-family: $font-family;
  border-radius: 0;

  &[type="text"], &[type="password"], &[type="number"],
  &[type="date"], &[type="email"], &.select-dexpo {
    line-height: 2;
    border-radius: 0;
    height: $font-size-base * 3.5;

    &.sm-input {
      height: $font-size-base * 2.75;
    }
  }

  &:focus {
    border: $border-form;
    background: #ffffff;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cccccc;
    font-family: $font-family;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: #cccccc;
    font-family: $font-family;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: #cccccc;
    font-family: $font-family;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: #cccccc;
    font-family: $font-family;
  }

  &.select-dexpo {
    display: inline-block;
    background: $white url("../images/arrow_dow.png") no-repeat right 4px center;
    padding-right: 23px;
    background-size: 15px;
    opacity: 1;
    border-radius: 5px;
    min-width: 20rem;
    line-height: 1.2;
    width: auto;
    max-width: 100%;
    height: auto;
    flex: none;
    position: relative;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    &.select-default {
      border-radius: 0;
      height: $font-size-base * 3.5;
    }

    &.arrow-down {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAfQAAAH0Bx0gPAAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACeSURBVDiN1ZPBDcIwDEWfMwt7sAQLMEWvnaRRC0dG4tY9+FyMZKgTIZULkSwl9n9PudgkseeUXfQvBAYcgQNQJT2+gswKcAbuACsgYAKKJHrlv56cWQEGfwioPYnDNeSH12AMzTmTODyH3CiJGIiSJUocXj7hN0EiuThY/L6BN4JEcvVK4VSQSJpwU5BIUlgS1tsFMzsBSLo1M/+/TE92ZfjD1tAH3gAAAABJRU5ErkJggg==');
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      background-position: calc(100% - .5rem), 100% 0;
      background-size: .7em .8em;
      background-repeat: no-repeat;
    }

    &.both-arrows {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
      background-position: calc(100% - .1rem), 100% 0;
      background-size:  1.5em 1.5em;
      background-repeat: no-repeat;
    }
  }

  &::-ms-expand {
    display: none;
  }

  &.sm-input {
    line-height: 1.5;
    border-radius: 5px;
    border-color: $gray-light;
  }

  &.border-input {
    border-radius: 5px;
    border-color: $gray-light;
  }
}

// Stylesheet form group
.form-group {
  .input-group {
    .form-control {
      &.datetimepicker-input, &.datetimepicker {
        border-right: 0;
        border-radius: 0;
      }
    }

    .input-group-text {
      border-radius: 0;
      border: $border-form;
      border-left: 0;
      background: $white;
    }
  }

  .bootstrap-datetimepicker-widget {
    font-weight: normal;
  }
}

//Style sheet of file
.custom-file-dexpo {
  display: flex;
  align-items: center;

  .custom-file {
    height: 100%;
  }

  .custom-file-input {
    background: $white;
    border: 1px solid $gray-light;
    outline: none;
    font-family: $font-family;
    color: #cccccc;
    z-index: 4;
    height: 100%;

    &:focus {
      border: 1px solid $gray-light;
      background: #ffffff;

      ~ .custom-file-label {
        border-color: initial;
      }
    }

    &:hover {
      background: $white;
      cursor: pointer;
    }

    &.file-selected ~ .custom-file-label {
      color: $black-dark
    }
  }

  .custom-file-label {
    color: #cccccc;
    background: $white-dark;
    font-family: $font-family;
    border-radius: 0;
    border: 1px dashed $border-color-form;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 3;


    &:after {
      display: none;
    }

    &:hover {
      background: $white;
      cursor: pointer;
    }
  }

  .preview-image {
    min-width: 5rem;
    max-width: 5rem;
    height: 5rem;

    .img-thumbnail {
      padding: 0;
      border: 0;
    }
  }

  &.no-legend {
    .custom-file-label {
      &::after {
        display: none;
      }
    }
  }
}

.f-form-page {
  .form-control {
    width: 100%;
  }

  .text-append {
    font-weight: bold;
    font-size: .8rem;
    margin-top: .2rem;
    margin-bottom: 0;
  }
}

//Custom radio
.radio-dexpo {
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom-radio {
    min-height: 18px;
    max-height: 18px;
    min-width: 18px;
    max-width: 18px;
    border-radius: 100%;
    border: 1px solid $black-light;
    margin-right: 5px;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: none;
      height: 70%;
      width: 70%;
      color: $blue;
      background-color: $blue;
      border-radius: 100%;
      border: 1px solid;
      position: absolute;
      top: calc(50% + 2px);
      left: calc(50% + 2px);
      z-index: 2;
      -webkit-transform: translate(calc(-50% - 2px), calc(-50% - 2px));
      -moz-transform: translate(calc(-50% - 2px), calc(-50% - 2px));
      -ms-transform: translate(calc(-50% - 2px), calc(-50% - 2px));
      -o-transform: translate(calc(-50% - 2px), calc(-50% - 2px));
      transform: translate(calc(-50% - 2px), calc(-50% - 2px));
    }
  }

  input[type="radio"] {
    height: 0;
    width: 0;
    opacity: 0;

    &:checked {
      ~ .custom-radio {
        &::after {
          display: block;
        }
      }
    }
  }
}

// custom checkbox
.checkbox-dexpo {
  display: inline-flex;
  font-size: 1.05rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom-checkbox {
    min-height: 18px;
    max-height: 18px;
    min-width: 18px;
    max-width: 18px;
    border-radius: 5px;
    border: 1px solid $black-light;
    margin-right: 5px;
    margin-top: 3px;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: none;
      width: 13px;
      height: 5px;
      border-style: solid;
      border-width: 3px;
      border-color: transparent transparent $blue $blue;
      top: 0;
      -webkit-transform: translate(12%, 50%) rotate(-55deg);
      -moz-transform: translate(12%, 50%) rotate(-55deg);
      -ms-transform: translate(12%, 50%) rotate(-55deg);
      -o-transform: translate(12%, 50%) rotate(-55deg);
      transform: translate(12%, 50%) rotate(-55deg);
    }
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    opacity: 0;

    &:checked {
      ~ .custom-checkbox {
        &::after {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .select-dexpo, .form-control.select-dexpo {
    min-width: 13rem;
  }
}
