.text-red-light {
  color: $red-light;
}

.text-red {
  color: $red;
}

.text-red-alt {
  color: $red-alt;
}

.text-green {
  color: $green-alt;
}

.text-black-dark {
  color: $black-dark;
}

.text-blue {
  color: $blue;
}

.text-green-alt {
    color: $green-alt;
}

.text-blue-alt {
  color: $blue-alt !important;
}

.text-key-color {
  color: $key-color !important;
}

.text-accent-color {
  color: $accent-color !important;
}