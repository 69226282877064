@import "../variables";
@import "../components/special-stage-item";
@import "../components/btn_register";
.sm-mobile {
    display: none;
}

// Common section
.section-title {
    @include title-header;
    overflow: visible;
    font-size: 1.875rem;
    &::before {
        content: attr(data-overlay);
        @include title-behind;
        font-size: 10rem;
    }
}

.section-desc {
    p {
        margin: 0;
        line-height: 1.8;
    }
}

// Top header
.top-header {
    background-color: $key-color;
    padding-top: .875em;
    .top-slogan {
        overflow: hidden;
        max-height: 40rem;
        min-height: 30rem;
        position: relative;
        display: flex;
        img {
            display: inline-flex;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            width: 100%;
            &.sm-mobile {
                display: none;
            }
        }
        .slogan {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            display: -ms-flex;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            -webkit-flex: 1 1 auto;
            -moz-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            -o-flex: 1 1 auto;
            -khtml-flex: 1 1 auto;
            flex: 1 1 auto;
        }
        .slogan-text {
            padding: 0;
            margin: 5px 0;
            font-weight: bold;
            font-size: 2rem;
            line-height: 1.3;
            color: $black-light;
            &:nth-child(2) {
                color: $red-alt;
            }
        }
    }
    .top-intro {
        background-color: $key-color;
        position: relative;
        .content-intro {
            min-height: 10rem;
            position: relative;
            max-width: 96%;
            margin-left: auto;
            margin-right: auto;
            top: 110px;
            &.no-date-release {
                min-height: 3rem;
            }
        }
        .box-intro {
            background-color: $ice-landscape;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            z-index: 2;
        }
        .intro {
            img {
                max-width: 100%;
                &.sm-mobile {
                    display: none;
                }
            }
            .note-held {
                margin-top: .75rem;
                margin-bottom: 0;
                font-size: .6rem;
            }
        }
        .intro-button {
            background-color: $white;
            list-style: none;
            padding: 1.5em 1rem;
            display: flex;
            justify-content: center;
            margin: 5px 5px 0;
            li {
                width: 50%;
                max-width: 385px;
                &:first-child {
                    padding-right: .75rem;
                }
                &:last-child {
                    padding-left: .75rem;
                }
                .text-sub {
                    font-size: .75em;
                    font-weight: bold;
                }
                .btn-dexpo {
                    margin-top: .25rem;
                    line-height: 1.2;
                    padding: 10px;
                    min-width: 13rem;
                    width: 100%;
                    text-decoration: none;
                    font-size: 0.875rem;
                    height: 50px;
                }
                .text-for-pc {
                    display: block;
                }
                .text-for-sp {
                    display: none;
                }
            }

            .text-notify {
                text-align: center;
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
        .intro-description {
            background-color: $white;
            padding: 0 11rem;
            margin: 0 5px 5px;
            text-align: justify;
            font-size: 0.75rem;
        }
    }
}

// Section news list
.section-events {
    background-color: $key-color;
    color: $white;
    padding-bottom: 10rem;
    overflow-x: hidden;

    .section-title {
        color: $key-color;
        margin: 6rem auto 3rem auto;
        padding-top: 10rem;
        &::before {
            color: $key-color;
            opacity: 0.1;
            top: 75%;
        }
    }
    .section-desc {
        //max-width: 730px;
        margin: 0 auto;
        >p {
            font-size: 1.125rem;
            color: $black;
            &:first-child {
                margin-top: 1rem;
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 1.3;
            }
            &:nth-child(2) {
                margin-top: 1.5rem;
                span {
                    display: inline-block;
                }
            }
        }
    }
    .item-news {
        border: 0;
        border-radius: 0;
        height: 100%;
        .thumbnail-news {
            padding: 7px;
            height: 300px;
            overflow: hidden;
            display: flex;
            img {
                display: inline-flex;
                margin: 0 auto;
                -o-object-fit: cover;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .card-body {
            padding: 0;
        }
        .cate-news {
            color: $key-color;
            font-size: 0.8rem;
            padding: 4px;
            min-width: 150px;
            text-align: center;
            border-bottom: 1px solid;
            display: inline-block;
            line-height: 1;
            letter-spacing: 2px;
            margin-top: 1rem;
            strong {
                font-size: 1.1rem;
            }
        }
        .title-news {
            padding: 1.3rem 1.7rem;
            color: $black;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.3;
            text-decoration: none;
        }
        .desc-news {
            color: $black;
            padding: 0 1.7rem 1.3rem 1.7rem;
            p {
                margin-bottom: .25rem;
                color: $gray;
            }
        }
    }
}

// Section seminar
.section-seminar {
    background-color: $white;
    color: $black;
    padding-bottom: 6rem;
    overflow-x: hidden;

    .section-title {
        margin: 9rem auto;
        &:before {
            color: $key-color;
            opacity: 0.1;
        }
    }
    .other-text {
        font-weight: bold;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 3rem;
    }
    .other-button {
        margin-top: 2rem;
        background-color: $pink;
        color: $white;
        font-weight: bold;
        padding: .3rem 2rem;
        text-decoration: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        &:hover {
            color: $white;
        }
    }
}

// Section gallery
.section-gallery {
    padding-bottom: 2rem;
    .section-title {
        font-size: 1.875rem;
        margin: 2rem auto;
    }
    .logo-item {
        margin-bottom: 1.5rem;
    }
    .client-logo {
        border: 1px solid #cccccc;
        max-width: 320px;
        max-height: 200px;
        min-height: 200px;
        overflow: hidden;
        height: 100%;
        a {
            display: flex;
            height: 100%;
            width: 100%;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            background-color: $white;
        }
        img {
            display: inline-flex;
            max-width: 100%;
            max-height: 21rem;
            -o-object-fit: contain;
            object-fit: contain;
            height: 100%;
        }
    }
}

// Section exhibitor
.section-exhibitor {
    background-color: $white;
}

// Section sponsor
.section-sponsor {
    background-color: $white;
    .sponsor-header {
        margin: 0 auto 0.1rem auto;
        padding: .5rem;
        background-color:$key-color;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2;
    }
    .client-logo {
        max-width: 100%;
        max-height: 100%;
    }
}

// Section loto
.section-loto {
    min-height: 25rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

// Section ovice
.section-ovice {
    background-color: $white;
    .container-ovice {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section notify
.section-notify {
    background-color: $white;

    .notify-item {
        width: 100%;
        text-align: center;
    }

    img {
        max-width: 100%;
    }
}

// Section co-organizer
.section-co-organizer {
    background-color: $key-color;
    .container-co-organizer {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section sponsor-static
.section-sponsor-static {
    background-color: $white;
    .container-sponsor-static {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: 3.5rem;
    }
    img {
        max-width: 100%;
    }
}

// Section online
.section-online {
    padding-bottom: 8rem;
    .section-title {
        margin: 7rem auto 1.5rem auto;
        &:before {
            color: $key-color;
            opacity: .1;
        }
    }
    .section-desc {
        text-align: center;
        font-weight: bold;
        line-height: 1.8;
        font-size: 1.125rem;
    }
    .online-item {
        border: 1px solid $key-color;
        padding: 1.5rem;
        background-color: $white;
        height: 100%;
        .ol-title {
            color: $key-color;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 40px;
        }
        .ol-desc {
            margin-top: 1rem;
            line-height: 1.8;
            .ol-text-small {
                font-size: 0.75rem;
            }
        }
    }
}

// Section inquiry
.section-inquiry {
    padding-bottom: 3rem;
    background-color: $key-color;
    color: $white;
    .section-title {
        color: $white;
        margin: 3rem auto 1.8rem auto;
        &:before {
            color: rgba(255, 255, 255, 0.1);
        }
    }
    .section-desc {
        text-align: center;
    }
    .btn-inquiry {
        border: 1px solid;
        border-radius: 5px;
        padding: 0.5rem 1.2rem 0.5rem;
        min-width: 23rem;
        color: #ffffff;
        text-decoration: none;
        position: relative;
        display: inline-block;
        font-size: 0.8rem;
        margin-top: 1.5rem;
        &:hover {
            color: $white;
        }
        &:after {
            content: '';
            border: 1px solid;
            border-bottom-color: transparent;
            border-left-color: transparent;
            height: 8px;
            width: 8px;
            top: 50%;
            right: 1rem;
            margin-top: -4px;
            position: absolute;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

//Section contact
.section-contact {
    background-color: $white;
    color: $black;
    .section-title {
        color: $key-color;
        &:before {
            color: $key-color;
            opacity: 0.1;
        }
    }
    .btn-inquiry {
        color: $black;
        &:hover {
            color: $black;
        }
    }
}

// Footer style
footer {
    background-color: $key-color;
    color: $white;
    align-items: center;
    font-size: .9rem;
    padding: .8rem 0;
}

@media screen and (max-width: 1024px) {
    .top-header {
        .top-intro {
            .intro {
                .intro-description {
                    padding: 0 4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    // Style of header
    .top-header {
        .top-intro {
            .intro {
                .term-held {
                    span {
                        font-size: 1.2rem;
                    }
                }
                .intro-description {
                    padding: 0 2rem;
                }
            }
        }
        .top-slogan {
            .slogan-text {
                line-height: 1.1;
                font-size: 1.7rem;
                &:first-child {
                    margin-top: -3rem;
                }
            }
        }
    }
    .section-title {
        &::before {
            font-size: 4rem;
        }
    }
    // Section event
    .section-events {
        padding-bottom: 4rem;
        .section-desc {
            max-width: 92%;
            >p {
                &:first-child {
                    font-size: 1.375rem;
                    line-height: 1.6;
                }
            }
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;
        .section-title {
            margin: 2rem auto;
        }
        .other-text {
            margin-top: 1rem;
        }
    }
    // Section online
    .section-online {
        .section-title {
            margin-top: 2rem;
        }
    }
    // Section inquiry
    .section-inquiry {
        .section-title {
            margin-top: 2rem;
        }
    }
    // Footer style
    footer {
        div[class^="col-"] {
            padding: 0;
        }
    }
}

@media screen and (max-width: 639px) {
    // Style of header
    .top-header {
        .top-slogan {
            min-height: 25rem;
        }
        .top-intro {
            .intro {
                max-width: 100%;
            }
            .intro-button {
                li {
                    margin-left: 0.25rem;
                    margin-right: 0.25rem;
                }
            }
        }
    }
    .section-title {
        &::before {
            font-size: 3rem;
            white-space: normal;
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;
    }
    .section-gallery {
        .col-sm-6,
        .col-md-3 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .section-sponsor {
        .gold-sponsors {
            .col-sm-6,
            .col-md-3 {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .top-header {
        .top-intro {
            .intro-button {
                li {
                    &:first-child {
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    // Section ovice
    .section-ovice, .section-notify {
        .container-ovice {
            padding: 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section co-organizer
    .section-co-organizer {
        .container-co-organizer {
            padding: 3rem 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section co-organizer
    .section-sponsor-static {
        .container-sponsor-static {
            padding: 3rem 0;
        }
        img {
            width: 100%;
        }
        .sm-mobile {
            display: block;
        }
        .pc-desktop {
            display: none;
        }
    }
    // Section online
    .section-online {
        .section-desc {
            text-align: left;
        }
        .col-sm-6,
        .col-md-4 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: .7rem !important;
            &:nth-child(odd) {
                padding-right: 5px;
            }
            &:nth-child(even) {
                padding-left: 5px;
            }
        }
        .online-item {
            padding: .5rem;
            .ol-desc {
                font-size: .9em;
            }
        }
    }
    .section-exhibitor {
        .client-logo {
            min-height: 100px;
        }
    }
    // Section seminar
    .section-seminar {
        padding-bottom: 4rem;
    }
}

@media screen and (max-width: 480px) {
    .only-pc {
        display: none;
    }
    // Style of header
    .top-header {
        padding-top: .625em;
        .top-slogan {
            display: flex;
            min-height: 15rem;
            max-height: 34rem;
            img {
                display: none;
                max-width: 100%;
                max-height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: top;
                object-position: top;
                &.sm-mobile {
                    display: inline-flex;
                    margin-top: -.5rem;
                    object-fit: cover;
                }
            }
            .slogan-text {
                font-size: 1.5rem;
                &:first-child {
                    margin-top: -8rem;
                }
            }
        }
        .top-intro {
            .container,
            .col-md-12,
            .row {
                padding: 0 !important;
                margin: 0 !important;
            }
            .content-intro {
                max-width: 100%;
                min-height: 15.5rem;
                top: 0;
                &.no-date-release {
                    min-height: 0rem;
                }
                .box-intro {
                    position: inherit;
                }
            }
            .intro {
                max-width: 100%;
                img {
                    display: none;
                    &.sm-mobile {
                        display: block;
                        width: 100%;
                    }
                }
                .intro-end-line {
                    height: 1px;
                }
                .intro-description {
                    padding: 0 3rem;
                    margin: 0 10px 10px;
                }
            }
            .intro-button {
                display: block;
                padding: 1.5rem 2.1rem;
                text-align: center;
                margin: 10px 10px 0;
                li {
                    width: 100%;
                    max-width: 270px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: .5rem;
                    &:last-child {
                        margin-top: 1.5rem;
                    }
                    .btn-dexpo {
                        line-height: 1.2;
                        font-size: .75rem;
                    }
                    .text-for-pc {
                        display: none;
                    }
                    .text-for-sp {
                        display: block;
                    }
                }

                .text-notify {
                    font-size: 0.875rem;
                    font-weight: normal;
                }
            }
        }
    }
    .section-title {
        font-size: 1rem;
        padding: 1.8rem 0;
        overflow: initial;
        &::before {
            font-size: 4rem;
            line-height: .9;
        }
    }
    .section-desc {
        p {
            font-size: .875rem;
        }
    }
    // Section event
    .section-events {
        .section-title {
            margin-top: 2.5rem;
            margin-bottom: 1rem;
            padding-top: 3rem;
        }
        .section-desc {
            font-size: .875rem;
            >p {
                &:last-child {
                    font-size: 1em;
                }
            }
        }
        .item-news {
            .thumbnail-news {
                height: 14.8rem;
            }
            .cate-news {
                text-align: left;
                padding-left: 1.25rem;
            }
            .title-news {
                font-size: 1.125rem;
                padding: 2.18rem 1.25rem .9rem;
            }
            .desc-news {
                padding-left: 1.1rem;
                padding-right: 1.1rem;
                font-size: .75rem;
            }
        }
    }
    // Section online
    .section-online {
        padding-bottom: 4rem;
        .section-title {
            padding: 3rem 0;
        }
        .section-desc {
            font-size: 0.938rem;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
            line-height: 2;
        }
        .online-item {
            .ol-title {
                font-size: 1.125rem;
                margin-bottom: auto;
                max-width: 75%;
                min-height: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .section-inquiry {
        .section-title {
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
        .section-desc {
            font-size: .75rem;
            max-width: 235px;
            margin-left: auto;
            margin-right: auto;
        }
        .btn-inquiry {
            min-width: 17rem;
            line-height: 1.5;
        }
    }
    // Footer style
    footer {
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 320px) {
    .section-inquiry {
        .btn-inquiry {
            min-width: 12rem;
        }
    }
}