.badge {
  &.badge-dexpo {
    line-height: $line-height-base;
    font-size: 80%;
    padding: .6rem .4rem;
    border-radius: 8px;
    text-align: left;
    min-width: 7rem;
    position: relative;

    > .close-dexpo {
      position: absolute;
      top: 50%;
      right: 5px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      color: inherit;
      font-size: 1.2rem;
      font-weight: normal;

      &:hover {
        opacity: .7;
        cursor: pointer;
      }
    }

    &.badge-warning {
      background: $yellow-dark;
      color: $white;
    }

    &.badge-success {
      background-color: $green-blur;
      color: $green-alt;
    }
  }

  &.badge-required {
    color: $red;
    border: 1px solid;
  }

  &.badge-option {
    color: $black-light;
    border: 1px solid;
  }
}


