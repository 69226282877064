.footer-menu {
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .menu-part {
        display: flex;
    }
    .menu-item {
        padding: 0.2rem .8rem;
        .menu-link {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.footer-powered {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: .3rem;
    font-size: .625rem;
    word-break: break-all;
    word-break: break-word;
    span {
        color: inherit;
        margin-left: .5rem;
        margin-right: .5rem;
    }
}

.top-content {
    .footer-menu {
        .menu-item {
            .menu-link {
                color: black;
            }
        }
    }
    .footer-powered {
        span {
            color: black;
        }
    }
}

footer {
    &[class="ft-single-line"] {
        padding: .5rem;
        .menu-part {
            align-items: center;
        }
        .container {
            max-width: 1366px;
        }
        .menu-item {
            font-size: .9em;
            padding: 2px 10px;
            .menu-link {
                text-decoration: underline;
            }
            &.footer-powered {
                margin-top: 0;
                justify-content: flex-start;
                font-size: 0.65em;
                width: auto;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .top-page {
        .board-right {
            // display: none;
        }
        .footer {
            background-color: $white;
        }
    }
    .footer-menu {
        padding: 0;
        margin-bottom: .5rem;
        justify-content: center;
        .menu-item {
            padding: 0 1rem;
            line-height: 1;
            .menu-link {
                font-size: .8em;
            }
            &:last-child {
                border-left: 1px solid;
            }
        }
        .menu-part {
            &:last-child {
                .menu-item {
                    &:first-child {
                        border-left: 1px solid;
                    }
                }
            }
        }
    }
    .footer-powered {
        display: block;
        font-size: .9em;
        text-align: center;
        margin-top: 0;
        span {
            display: block;
            margin-left: 0;
            margin-right: 0;
            &:first-child {
                padding: .3rem;
            }
            &:last-child {
                padding-bottom: 2rem;
            }
        }
    }
    footer {
        &[class="ft-single-line"] {
            .container {
                max-width: 100%;
            }
            .footer-menu {
                margin-bottom: 0;
            }
            .menu-part {
                .menu-item {
                    border: 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .footer-menu {
        .menu-item {
            padding: 0 .5rem;
            .menu-link {
                font-size: .6em;
            }
        }
    }
    .footer-powered {
        font-size: .8em;
    }
}