.btn-dexpo {
  background: $key-color;
  border: none;
  border-radius: $border-radius;
  opacity: 1;
  width: auto;
  min-width: 10rem;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  font-size: $font-size-base;
  font-family: $font-family;
  color: $white;

  &:hover {
    color: $white;
  }

  &.btn-sm {
    min-width: 120px;
    line-height: 1.4;
    flex: none;
  }

  &.btn-medium {
    min-width: 8rem;
    line-height: 1.6;
  }

  &.btn-lg {
    min-width: 10rem;
    line-height: 1.9;
  }

  &.btn-super-lg {
    min-width: 14rem;
    line-height: 2.7;
  }

  &.btn-dark {
    background: $dark;
  }

  &.btn-black {
    background: $black;
  }

  &.btn-red-alt {
    background: $red-alt;
  }

  &.btn-red {
    background: $red;
  }

  &.btn-red-dark {
    background-color: $red-dark;
  }

  &.btn-red-light {
    background: $red-light;
  }

  &.btn-silve {
    background-color: #E3E3E3;
    color: #989898;
  }

  &.btn-gray-alt {
    background-color: $gray-alt;
    color: $white;
  }

  &.btn-orange-light {
    background: #EA5B20;
    color: #ffffff;
  }

  &.btn-green {
    background-color: $green-alt;

    &:not(:disabled) {
      &:not(.disbaled) {
        &:active {
          background-color: $green-dark;
        }
      }
    }
  }

  &.btn-green-light {
    background: $green-blur;
    color: $green-alt;

    &:not(:disabled) {
      &:not(.disbaled) {
        &:active {
          background-color: $green-dark;
          color: $white;
        }
      }
    }
  }

  &.btn-white {
    background-color: $white;
    color: $black;
  }

  &.arrow-right::after {
    content: "";
    border: 1px solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    height: 8px;
    width: 8px;
    top: 50%;
    right: 10px;
    margin-top: -4px;
    position: absolute;
    transform: rotate(45deg);
  }

  &.btn-border {
    border: .15rem solid;
  }

  &.btn-key-color {
    background-color: $key-color;
  }

  &.btn-accent-color {
    background-color: $accent-color;
  }
}

