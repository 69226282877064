.pagination-dexpo {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;

    .page-item {
      margin: 1rem 0.4rem 0 0.4rem;

      .page-link {
        display: block;
        padding: 0;
        text-decoration: none;
        width: 25px;
        text-align: center;
        line-height: 25px;
        background-color: transparent;
        color: #989898;
        border: 0;
        border-radius: 0;
        font-size: 1rem;
      }

      &.active {
        .page-link {
          color: $blue-dark;
          border-bottom: 2px solid;
          width: auto;
          min-width: 20px;
        }
      }
    }
  }
}
