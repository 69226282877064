.top-ft-bottom {
  background-color: rgba(7,4,4,0.6 );
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .btn-dexpo {
    padding: .3rem 1rem;
    text-decoration: none;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    min-width: 22.5rem;

    span {
      display: block;

      &:first-child {
        font-size: .75rem;
      }

      &:last-child {
        font-size: 1.125rem;
      }
    }

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}