// Body
$body-bg: #f8fafc;
// Colors
$white: #fff;
$white-dark: #f7f7f7;
$red: #FF0000;
$red-dark: #B60A09;
$red-light: #FF3B00;
$dark: #838383;
$yellow-dark: #FFA700;
$green-alt: #24B395;
$green-blur: #DAF3ED;
$green-dark: #147862;
$blue-alt: #106D9B;
$blue: #006E9C;
$blue-blur: #E6EDF5;
$blue-dark: #006E9D;
$blue-aqua: #73C4D3;
$red-alt: #ea5b1f;
$black: #3c3c3c;
$black-light: #525252;
$black-dark: #000000;
$yellow: #FDD400;
$pink: #FF2EEF;
$sky-light: #EAF9FC;
$gray: #333333;
$gray-brown: #a5a5a6;
$gray-light: #EBEBEB;
$gray-alt: #9D9D9D;
$orange-light: #ea5b20;
$ice-landscape: #c3e8f8;
$blue-black :#02267F;
// Body
$body-bg: $white;
// Typography
$font-family: "Helvetica Neue",
"Helvetica",
"Hiragino Sans",
"Hiragino Kaku Gothic ProN",
"Arial",
"Yu Gothic",
"Meiryo",
sans-serif;
$font-size-base: 0.9rem !default;
$line-height-base: 1.6;
$font-color: #484848;
// Color link
$color-link: #00007C;
// Border
$border-color-form: #6C6C6C;
$border-color-table: #F2F2F2;
$border-width: 1px;
$border-radius: 5px;
$border: $border-width solid $border-color-form;
$border-table: $border-width solid $border-color-table;
$border-form: $border-width solid $border-color-form;
// color-menu
$color-menu: rgba(15, 110, 157, 1);
// Tab
$tab-color: $blue-dark;
$tab-bg: $white;
$tab-active-bg: #EAF9FC;

$key-color: var(--m0d1r-key-color);
$sub-color: var(--m0d1r-sub-color);
$accent-color: var(--m0d1r-accent-color);