.progress-dexpo {
  position: relative;
  font-size: 1em;

  &.loading, &.success, &.failed {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .3);
      z-index: 2;
    }
  }

  &.loading {
    &:after {
      content: '';
      position: absolute;
      width: 2em;
      height: 2em;
      top: 50%;
      margin-top: -1em;
      left: 50%;
      margin-left: -1em;
      border-radius: 100%;
      border-style: solid;
      border-width: .3em;
      border-color: transparent #fff transparent #fff;
      z-index: 3;
      animation: spin infinite 1s;
    }
  }

  &.success {
    &:after {
      position: absolute;
      width: 2em;
      top: 50%;
      left: 50%;
      margin-left: -1em;
      -webkit-transform: translate(12%, 50%) rotate(-55deg);
      -moz-transform: translate(12%, 50%) rotate(-55deg);
      -ms-transform: translate(12%, 50%) rotate(-55deg);
      -o-transform: translate(12%, 50%) rotate(-55deg);
      transform: translate(12%, 50%) rotate(-55deg);
      content: "";
      height: 1em;
      margin-top: -1.5em;
      border-style: solid;
      border-color: transparent transparent #75ff94 #75ff94;
      z-index: 2;
    }
  }

  &.failed {
    &:after {
      content: "×";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -.3em;
      margin-top: -.5em;
      font-size: 2rem;
      line-height: 1;
      display: inline-block;
      color: #ff3333;
      z-index: 2;
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}