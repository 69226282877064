.block-seminar {
  border: 1px solid $key-color;
  background-color: $white;
  padding: 3.125rem;
  margin-bottom: 2rem;

  .s-row {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    &:first-child {
      padding-bottom: 1rem;

      .s-col {
        &:first-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }

    &.s-item {
      padding-top: .7rem;
      padding-bottom: .7rem;

      .s-col {
        &:first-child {
          .s-profile-comp, .s-profile {
            padding-left: 0;
          }
        }
      }
    }
  }

  .text-dt {
    color: $key-color;
    line-height: 1.1;
    font-weight: bold;

    strong {
      font-size: 2.5rem;
    }

    span {
      font-weight: bold;
      margin-left: .25rem;
      font-size: 1.7rem;
    }

    br {
      display: none;
    }

    &:last-child {
      margin-top: .5rem;
    }
  }

  .s-title {
    padding: .5rem 0;

    a {
      color: $key-color;
      font-size: 1.8rem;
      line-height: 1.3;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .s-thumb {
    width: 25rem;
    background-color: transparent;
    display: flex;
    overflow: hidden;
    min-height: 100px;
    max-height: 300px;
    border: 1px solid $key-color;

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      display: inline-flex;
      -o-object-fit: fill;
      object-fit: fill;
      font-family: 'object-fit: fill;';
    }
  }

  .s-profile {
    font-weight: bold;
    padding-left: 1.5rem;
    font-size: 1.2rem;
    word-break: break-all;
    word-break: break-word;

    p {
      margin-bottom: .5rem;
    }
  }

  .s-profile-comp {
    line-height: 1.7;
    padding-top: 1rem;
    padding-left: 1.5rem;
    word-break: break-all;
    word-break: break-word;
  }

  .show-btn-detail {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-detail {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $key-color;
    border: 1px solid;
    border-radius: 5px;
    padding: .6rem .3rem;
    min-width: 12rem;
    word-break: break-all;
    margin-top: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &.btn-watch {
      background-color: $accent-color;
      color: $white;
      border-color: $accent-color;
    }

    &.btn-gray {
      background-color: gray;
      border-color: gray;
    }

    span {
      font-size: .7em;
      margin-left: .5rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .block-seminar {
    .s-thumb {
      width: 15rem;
    }
  }
}


@media screen and (max-width: 768px) {
  .block-seminar {
    padding: 2rem 1.5rem;

    .text-dt {
      strong {
        font-size: 2rem;
      }

      span {
        font-size: 1.3rem;
      }
    }

    .s-profile, .s-profile-comp {
      padding-left: 0;
    }

    .s-row {
      display: block;

      .s-col {
        display: block;
      }
    }

    .s-title {
      a {
        font-size: 1.4rem;
      }
    }

    .s-thumb {
      width: 100%;
      margin-bottom: 1rem;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-height: initial;
      display: block;

      img {
        display: inline-block;
        max-height: none;
        object-fit: initial;
      }
    }

    .s-profile-comp {
      padding-top: .5rem;
    }

    .show-btn-detail {
      margin-left: auto;
      margin-right: auto;
    }

    .btn-detail {
      margin-right: auto;
      margin-left: auto;
      margin-top: 1rem;
      max-width: 250px;

      &:last-child {
        margin-right: auto;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .block-seminar {
    padding: 1rem;

    .text-dt {
      br {
        display: inline-block;
      }
    }
  }
}