.table-dexpo {
  border: 1px solid transparent;

  thead {
    th {
      border-top: 0;
      border-bottom: $border;
      border-collapse: collapse;
    }

    th {
      border-top: none;
      color: $black-dark;
    }
  }

  tbody {
    border-left: $border;
    border-right: $border;

    tr {
      td {
        border-top: none;
        border-bottom: $border;
      }
    }
  }

  &.table-striped {
    tbody  {
      tr:nth-of-type(odd) {
        background-color: #F2F2F2;
      }
    }
  }

  &.align-vertical-mid {
    td {
      vertical-align: middle;
    }
  }

  &.align-center {
    td {
      text-align: center;
    }
  }
}